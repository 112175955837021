import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import slide1 from '../../../static/assets/slider/desktop/slide-1.png';
import slide2 from '../../../static/assets/slider/mobile/slide-2.png';
import slide3 from '../../../static/assets/slider/desktop/slide-3.png';
import slide4 from '../../../static/assets/slider/mobile/slide-4.png';
import slide5 from '../../../static/assets/slider/desktop/slide-5.png';
import slide6 from '../../../static/assets/slider/mobile/slide-6.png';
import slide7 from '../../../static/assets/slider/desktop/slide-7.png';
import slide8 from '../../../static/assets/slider/mobile/slide-8.png';

const WebPage = () => (
  <Layout>
    <SEO title="EasyBusy Web: Make achieving easier!" />

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Start using web version</h2>
          <p>
            Use app in your browser on any device or computer and sync all your data
            with your mobile devices or across multiple computers.
          </p>

          <div className={'buttons-list'}>
            <div className={'button'}>
              <a
                href="https://app.easybusydo.com"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fab fa-safari" />
                Get started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'page-header home'}>
      <img alt={'slide1'} src={slide1} />
      <img alt={'slide2'} src={slide2} />
      <img alt={'slide3'} src={slide3} />
      <img alt={'slide4'} src={slide4} />
      <img alt={'slide5'} src={slide5} />
      <img alt={'slide6'} src={slide6} />
      <img alt={'slide7'} src={slide7} />
      <img alt={'slide8'} src={slide8} />
    </div>
  </Layout>
);

export default WebPage;
